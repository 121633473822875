<template >
  <div>
    
    <h1>Bienvenido a su apartado para definir encuestas</h1>
    

    
    <h5>Ingrese nombre de la encuesta</h5>
    <b-form-input v-model="encuesta.nombreEncuesta" placeholder="Ingrese el nombre con el cual se reconocera esta encuesta"></b-form-input>

    <h5>Ingrese una pregunta</h5>
    <b-form-input v-model="preguntaActual.nombre" placeholder="Ingrese su pregunta empezando en mayuscula y encerrandola entre signos de interrogación"></b-form-input>
    <b-form-select v-model="preguntaActual.tipo" :options="tipoPregunta" > </b-form-select>
    
    <div v-if="preguntaActual.tipo == 'Múltiple'">
      <b-form-input v-model="opcionPreguntaActual" placeholder="Inserte una opción para su pregunta"></b-form-input>
      <br>
      <b-button @click="preguntaActual.opciones.push(opcionPreguntaActual)">Agregar opcion</b-button>
      <h5 v-for="opcionP in preguntaActual.opciones" :key="opcionP">
        {{opcionP }}
      </h5>
    </div>
    <br>
    <b-button @click="agregarPreguntaActual()">
      Guardar pregunta
    </b-button>

    <b-button @click="onReset()" variant="danger">
      Borrar encuesta
    </b-button>

    <br>
    <h6>Resumen Pregunta</h6>
    {{preguntaActual}}
    <br>
    <h6>Resumen Pregunta</h6>
    {{encuesta}}
  </div>
</template>




<script>
  import axios from 'axios'
  export default {

    data() {
      return {
        //variables que se vana regresar
        resultado:'Aqui se muestra la información',
        usuario:'',
        pass:'',

        opcionPreguntaActual:'',
        preguntaActual:{
              no:null,
              nombre:'',
              tipo:'Múltiple',
              opciones:[]
            },

        encuesta:{
          idEncuesta:'',
          nombreEncuesta:'',
          preguntas:[
            {
              // no:null,
              // nombre:'',
              // tipo:'Múltiple',
              // opciones:[]
            }
          ]
        },

        tipoPregunta:['Múltiple', 'Númerico', 'Si / No']

      }
    },

    methods: { 

      onReset(event) {
        event.preventDefault()
        // Valores al Resetear encuesta
        this.preguntaActual.nombre = null
        this.preguntaActual.tipo = null
        this.preguntaActual.opciones = null
      },

      agregarPreguntaActual(){
        this.encuesta.preguntas.push(this.preguntaActual)
      },
      /*METODO PARA VISUALIZAR RESULTADOS*/
      visualizarResultado() {
        alert('Se muestran los resultados');

        axios({
          method: 'get',
          url: 'https://recaudacion.gobinet.mx/servicios',
          params:{"codeClient":"calimaya"},
          data: {
              "idToken": '',
              "usuario":this.usuario,
              "pass":this.pass
          }
        })
        .then(res => {
            this.resultado = res.data;
        }).catch(err => {
            //errores
            alert(err);
        });
        },

        /*METODO PARA VISUALIZAR ENCUESTA*/
        visualizarEncuesta(){
          alert('Se muestra la encuesta');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },
      }
    }
  
</script>



<style>
.backmycolor{
  background-color: aqua;
}
</style>